import Api from '@/composables/useApi'
import {ref, watch, onMounted, onUnmounted, toRef} from 'vue'
import { yearFilter, regionFilter, clubFilter } from '@/composables/useFilters'
import {isOlympicsYear} from "@/composables/utils";

const useAccResultatsWidget = () => {

  const loaded = ref(false)
  const watcherList = ref([])
  const voData = ref([])
  const hnData = ref([])
  const data = () => [voData.value, hnData.value]

  const indicateurs = toRef([
    { id: 'titres', name: 'Or', selected: true},
    { id: 'medailleArgent', name: 'Argent', selected: true},
    { id: 'medailleBronze', name: 'Bronze', selected: true},
  ])

  onMounted(async () => {

    const watchers = [
      yearFilter.singleYearFilter,
      regionFilter.selectedLigue,
      clubFilter.selectedPPFStructure,
    ]

    watchers.forEach((target) => {
      const watcher = watch(target, (value, oldValue) => {
        if(value !== oldValue) fetchResultats()
      })
      watcherList.value.push(watcher)
    })
    fetchResultats()
  })

  onUnmounted(() => {
    watcherList.value.forEach((unwatch) => unwatch())
  })

  function handleRequestBody(disciplineFamille) {
    const body = {
      series: {
        series : [{
          codeBateau: null,
          codeBateauLibell: null,
          disciplineFamille: disciplineFamille,
          disciplineCategorie: null,
          secteurCode: null,
          sexe: null
        }]
      },
      competitions: {
        competitions: disciplineFamille === "VO" && isOlympicsYear(yearFilter.singleYearFilter.value) ? ["JO"] : ["CM"]
      },
      annees: {
        anneeDebut: yearFilter.singleYearFilter.value,
        anneeFin: yearFilter.singleYearFilter.value,
      },
      ages: {
        ageMin: null,
        ageMax: null,
      },
      collectifs: {
        collectifs: [""]
      }
    }
    
    if(regionFilter.selectedLigue.value)
      body.ligue = {
        ligue: regionFilter.selectedLigue.value.id
      }

    if(clubFilter.selectedPPFStructure.value)
      body.structures = {
        structureCodes: [clubFilter.selectedPPFStructure.value.code]
      }

    return body
  }

  async function fetchResultats() {
    try {
      loaded.value = false

      const voResponse = await Api.post('/stats/indicateursperformance/resultats', handleRequestBody("VO"))
      voData.value = handleResults("VO", "Voile Olympique", voResponse.data)

      const hnResponse = await Api.post('/stats/indicateursperformance/resultats', handleRequestBody("DHN"))
      hnData.value = handleResults("DHN", "Disciplines de Haut Niveau", hnResponse.data)
    }
    catch (err) {
      console.error(err)
    }
    loaded.value = true
  }

  function handleResults(id, name, results) {

    const competitionId = id === "VO" && isOlympicsYear(yearFilter.singleYearFilter.value) ? "JO" : "CM"
    const competitionName = id === "VO" && isOlympicsYear(yearFilter.singleYearFilter.value)
      ? "Jeux Olympiques"
      : "Championnats du Monde"

    return {
      id,
      name,
      competitions: [{
        id: competitionId,
        name: competitionName,
        indicateurs: indicateurs.value.map((indic) => {

          const indicData = {
            name: indic.name
          }

          const targetCompet = results.filter((el) => el.hnNiveauId === competitionId)
          if (targetCompet.length) {
            if (indic.id === "meilleurePlace") {
              indicData.value = targetCompet.map((el) => el[indic.id]).sort((a, b) => a - b).at(0)
            } else {
              indicData.value = results
                .filter((el) => el.hnNiveauId === competitionId)
                .reduce((acc, val) => acc + val[indic.id], 0)
            }
          } else {
            indicData.value = null
          }

          return indicData
        })
      }]
    }
  }

  return {
    loaded,
    data,
  }
}

export default useAccResultatsWidget