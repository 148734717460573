<template>
  <FilterSimple
      name="Catégories d'âge"
      :elements="ageCategories.map((c) => ({
        ...c,
        label: c.libelle,
        isSelected: isAgeCategoryTmpSelected(c),
      })).sort((c1, c2) => c1.ordre - c2.ordre)"
      :select-element="selectAgeCategory"
      :confirm-selection="confirmAgeCategories"
      :reset="resetAgeCategories"
  />
</template>

<script setup>
import FilterSimple from "@/components/common/FilterSimple.vue";

import {resultatFilter} from '@/composables/useFilters'

const {
  ageCategories,
  isAgeCategoryTmpSelected,
  selectAgeCategory,
  confirmAgeCategories,
  resetAgeCategories,
} = resultatFilter

</script>