import {ref} from 'vue'
import Api from '@/composables/useApi'
import { yearFilter } from '../useFilters'
import useAgeFilter from "@/composables/filters/useAgeFilter";
import {sortAlphabetically} from "@/composables/widgets/_widgetUtils";
import {isOlympicsYear} from "@/composables/utils";

/**
 * Tous les composants filtres sont regroupés dans le widget
 * -> @/components/pages/haute-performance/filtres/ResultatFilter.vue
 * 
 * et sont rangés ici :
 * -> @/components/pages/haute-performance/filtres/resultat-filter/...
*/

const series = ref([])
const tmpSelectedSeries = ref([])
const selectedSeries = ref([])

const competitions = ref([])
const tmpSelectedCompetitions = ref([])
const selectedCompetitions = ref([])

const ageCategories = ref([])
const tmpSelectedAgeCategories = ref([])
const selectedAgeCategories = ref([])

const indicateurs = ref([])
const selectedIndicateurs = ref([])
const initialIndicators = [
  { id: 'titres',         name: 'Or',                 selected: true},
  { id: 'medailleArgent', name: 'Argent',             selected: true},
  { id: 'medailleBronze', name: 'Bronze',             selected: true},
  { id: 'podiums',        name: 'Podiums',            selected: false},
  { id: 'top10',          name: 'Top 10',             selected: true},
  { id: 'meilleurePlace', name: 'Meilleure Place',     selected: false},
  { id: 'top20',          name: 'Top 20',             selected: false},
  { id: 'pct50',          name: '50%',                selected: false},
  { id: 'nombreFranais',  name: 'Nbr de Français', selected: false},
]

const INITIAL_AGE_CATEGORIES = [
  { id: 'all', label: 'Tout âge', ageBegin: 0,  ageEnd: 150, isSelected: true, ordre: 0, isCategory: true },
  { id: 'u15', label: 'U 15', ageBegin: 0, ageEnd: 14, isSelected: false, ordre: 1, isSubCategory: true },
  { id: 'u17', label: 'U 17', ageBegin: 15, ageEnd: 16, isSelected: false, ordre: 2, isSubCategory: true },
  { id: 'u19', label: 'U 19', ageBegin: 17, ageEnd: 18, isSelected: false, ordre: 3, isSubCategory: true },
  { id: 'u21', label: 'U 21', ageBegin: 19, ageEnd: 20, isSelected: false, ordre: 4, isSubCategory: true },
  { id: 'u23', label: 'U 23', ageBegin: 21, ageEnd: 22, isSelected: false, ordre: 5, isSubCategory: true },
  { id: 'u26', label: 'U 26', ageBegin: 23, ageEnd: 25, isSelected: false, ordre: 6, isSubCategory: true },
]

const collectifs = ref([])
const tmpSelectedCollectifs = ref([])
const selectedCollectifs = ref([])

const useResultatFilter = () => {

  // -•-----------------------------•-
  // Fetch filter data

  async function fetchSeries() {
    const { data } = await Api.get('/dictionnaires/disciplines/hn', { params: {
      anneeDebut: yearFilter.singleYearFilter.value,
      anneeFin: yearFilter.singleYearFilter.value
    }})

    let seriesArbo = []
    const getFamilyIndex = (familyName) => seriesArbo.findIndex((f) => f.id === familyName)
    const getCategoryIdOrSecteurCodeIndex = (familyIndex, categoryOrSecteurCode) => {
      const index = seriesArbo[familyIndex].children.findIndex((f) => f.id === categoryOrSecteurCode)
      return index > -1 ? index : seriesArbo[familyIndex].children.findIndex((f) => f.id === categoryOrSecteurCode)
    }

    data.forEach((result) => {
      let familyIndex = getFamilyIndex(result.familleId)
      if (familyIndex === -1) {
        seriesArbo.push({
          all: true,
          selected: true,
          isOpen: false,
          id: result.familleId,
          name: result.familleLibelle,
          ordre: result.familleOrdre,
          parent: false,
          raw: { familleId: result.familleId },
          children: []
        })
        familyIndex = seriesArbo.length - 1
      }
      const categoryIdOrSecteurCode = result.categorieId || result.secteurCode
      const isSecteurCode = categoryIdOrSecteurCode === result.secteurCode
      let categoryIdOrSecteurIndex = getCategoryIdOrSecteurCodeIndex(familyIndex, categoryIdOrSecteurCode)
      if (categoryIdOrSecteurIndex === -1) {
        seriesArbo[familyIndex].children.push({
          selected: false,
          isOpen: false,
          id: categoryIdOrSecteurCode,
          name: isSecteurCode ? result.secteurLibelle : result.categorieLibelle,
          ordre: result.categorieOrdre,
          parent: seriesArbo[familyIndex].id,
          children: [],
          raw: {
            familleId: seriesArbo[familyIndex].id,
            categorieId: !isSecteurCode ? categoryIdOrSecteurCode : null,
            secteurCode: isSecteurCode ? categoryIdOrSecteurCode : null,
          },
        })
        categoryIdOrSecteurIndex = seriesArbo[familyIndex].children.length - 1
      }
      seriesArbo[familyIndex].children[categoryIdOrSecteurIndex].children.push({
        selected: false,
        id: result.codeBateau,
        name: result.codeBateauLibelle,
        sexe: result.sexe,
        parent: seriesArbo[familyIndex].children[categoryIdOrSecteurIndex].id,
        raw: result
      })
    })

    seriesArbo.forEach((f) =>
        f.children.forEach(
            (sf) => sf.children.sort((s1, s2) => sortAlphabetically(s1.name, s2.name))
        )
    )
    seriesArbo.forEach((f) => f.children.sort(
        (sf1, sf2) => {
          return f.children.map(sf => sf.ordre).filter(o => !!o).length === f.children.length
            ?  sf1.ordre - sf2.ordre
            : sortAlphabetically(sf1.name, sf2.name)
        }
    ))
    seriesArbo = seriesArbo.sort((f1, f2) => f1.ordre - f2.ordre)

    series.value = seriesArbo
    tmpSelectedSeries.value = series.value
    selectedSeries.value = series.value
  }

  async function fetchCompetitions() {
    const { data } = await Api.get('/dictionnaires/competitions/niveauxHn')
    competitions.value = data.map((el) => ({
      ...el,
      selected: el.id === "JO" || el.id === "CM" || el.id === "CMJ"
    }))
    tmpSelectedCompetitions.value = competitions.value.filter((el) => el.selected)
    selectedCompetitions.value = [...tmpSelectedCompetitions.value]
  }

  async function fetchAgeCategories() {
    const { data } = await Api.get('/dictionnaires/categoriesAge/hn')
    ageCategories.value = data.map((el) => ({
      ...el,
      selected: false
    }))
  }

  const setupIndic = () => {
    indicateurs.value = initialIndicators
    resetIndics()
  }

  async function fetchCollectifs() {
    const { data } = await Api.post('/stats/indicateursperformance/filters/collectifs')
    collectifs.value = data.map((el) => ({ ...el, selected: false }))
    tmpSelectedCollectifs.value = []
    selectedCollectifs.value = []
  }

  const isFiltersDataFetched = () => {
    return (series.value.length > 0 && competitions.value > 0 && collectifs.value > 0)
  }

  // -•-----------------------------•-
  // Gestion des filtres

  // Séries : SerieFilter.vue
  function FLAT() {
    return series.value.reduce((acc, val) => {
      acc.push(val)
      val.children.forEach((el) => {
        acc.push(el)
        el.children.forEach((el2) => {
          acc.push(el2)
        })
      })
      return acc
    }, [])
  }
  
  const FIND = (ID) => FLAT().find((el) => el.id === ID)
  const SIBLINGZ = (EL) => FLAT().filter((el) => el.parent === EL.parent)

  function SUPER_TOGGLE(EL) {
    const parent = FIND(EL.parent)
    const gParent = parent ? FIND(parent.parent) : false
    const children = EL.children ??= false

    EL.selected = !EL.selected
  
    if(EL.selected) {
      // Décoche la catégorie parente ou grand parente si elle était sélectionnée
      if(parent && parent.selected || gParent && gParent.selected) {
        if(gParent && gParent.selected) {
          gParent.selected = false
          SIBLINGZ(parent).forEach((el) => el.selected = el !== parent)
        }
        else {
          parent.selected = false
        }
        SIBLINGZ(EL).forEach((el) => el.selected = el !== EL)
      }
  
      // décoche les catégories enfants
      if(children)
      children
        .reduce((acc, val) => {
          acc.push(val)
          if(val.children) acc.push(...val.children)
          return acc
        }, [])
        .forEach((el) => el.selected = false)
    }
  
    // Si toutes les catégories du même groupe sont sélectionnées,
    // les décoche pour cocher la catégorie parente, ou grand parente à la place
    if(!SIBLINGZ(EL).find((el) => !el.selected)) {
      if(parent) {
        parent.selected = true
        SIBLINGZ(EL).forEach((el) => el.selected = false)
      }
      if(gParent && !SIBLINGZ(parent).find((el) => !el.selected)) {
        gParent.selected = true
        SIBLINGZ(parent).forEach((el) => el.selected = false)
      }
    }
    setSelectedSeries(FLAT().filter((el) => el.selected))
  }

  function setSelectedSeries(newSeries) {
    tmpSelectedSeries.value = newSeries
  }

  function removeSerie(serie) {
    SUPER_TOGGLE(serie)
    if(!tmpSelectedSeries.value.filter((el) => el.raw.familleId === serie.raw.familleId).length)
      tmpSelectedSeries.value = [...tmpSelectedSeries.value, FIND(serie.raw.familleId)]
  }

  const openCategorie = (categorie) => {
    if (categorie.isOpen !== undefined) categorie.isOpen = !categorie.isOpen
  }

  const confirmSeriesSelection = () => selectedSeries.value = tmpSelectedSeries.value

  function resetSeries() {
    FLAT().forEach((el) => ({selected: el.selected = el.id === "VO" || el.id === "DHN"}))
    setSelectedSeries(FLAT().filter((el) => el.selected))
  }

  // Compétitions : CompetitionFilter.vue

  const selectCompetition = (competition) => {
    if (isCompetitionTmpSelected(competition)) {
      tmpSelectedCompetitions.value = tmpSelectedCompetitions.value.filter((c) => c.id !== competition.id)
    } else {
      tmpSelectedCompetitions.value = tmpSelectedCompetitions.value.concat(competition)
    }
    if (tmpSelectedCompetitions.value.length === 0) resetCompetitions()
  }

  const isCompetitionSelected = (competition) => selectedCompetitions.value.findIndex((c) => c.id === competition.id) > -1

  const isCompetitionTmpSelected = (competition) => tmpSelectedCompetitions.value.findIndex((c) => c.id === competition.id) > -1

  const confirmCompetitions = () =>  selectedCompetitions.value = tmpSelectedCompetitions.value
  const resetCompetitions = () => {
    tmpSelectedCompetitions.value = competitions.value.filter((el) => el.id === "JO" || el.id === "CM" || el.id ==="CMJ")
    selectedCompetitions.value = tmpSelectedCompetitions.value
  }

  // Categories d'âge
  const selectAgeCategory = (ageCategory) => {
    if (isAgeCategoryTmpSelected(ageCategory)) {
      tmpSelectedAgeCategories.value = tmpSelectedAgeCategories.value.filter((c) => c.id !== ageCategory.id)
    } else {
      tmpSelectedAgeCategories.value = tmpSelectedAgeCategories.value.concat(ageCategory)
    }
  }

  const isAgeCategorySelected = (ageCategory) => selectedAgeCategories.value.findIndex((c) => c.id === ageCategory.id) > -1

  const isAgeCategoryTmpSelected = (ageCategory) => tmpSelectedAgeCategories.value.findIndex((c) => c.id === ageCategory.id) > -1

  const confirmAgeCategories = () =>  selectedAgeCategories.value = tmpSelectedAgeCategories.value
  const resetAgeCategories = () => selectedAgeCategories.value = []

  // Indicateurs : IndicateurFilter.vue
  const selectIndicator = (indicator) => {
    if (isIndicatorSelected(indicator)) {
      selectedIndicateurs.value = selectedIndicateurs.value.filter((i) => i.name !== indicator.name)
      if (selectedIndicateurs.value.length === 0) resetIndics()
    } else {
      selectedIndicateurs.value = selectedIndicateurs.value.concat(indicator)
    }
  }
  const isIndicatorSelected = (indicator) => {
    return selectedIndicateurs.value.findIndex((i) => i.name === indicator.name) > -1
  }
  const resetIndics = () => {
    selectedIndicateurs.value = indicateurs.value.filter((i, index) => initialIndicators[index].selected)
  }
  
  // Collectifs : CollectifFilter.vue

  const isCollectifSelected = (collectif) =>
      selectedCollectifs.value.findIndex((c) => c.tcolIds === collectif.tcolIds) > -1

  const isCollectifTmpSelected = (collectif) =>
      tmpSelectedCollectifs.value.findIndex((c) => c.tcolIds === collectif.tcolIds) > -1

  const selectCollectif = (collectif) => {
    if (isCollectifTmpSelected(collectif)) {
      tmpSelectedCollectifs.value = tmpSelectedCollectifs.value.filter((c) => c.tcolIds !== collectif.tcolIds)
    } else {
      tmpSelectedCollectifs.value = tmpSelectedCollectifs.value.concat(collectif)
    }
  }

  const confirmCollectifSelection = () => selectedCollectifs.value = tmpSelectedCollectifs.value
  function resetCollectifs() {
    tmpSelectedCollectifs.value = []
    selectedCollectifs.value = []
  }

  const getSortedSelectedCompetitions = (type = "") => selectedCompetitions.value
    .filter(c => {
      if (type.toLowerCase() === "vo") return c.vo === true
      if (type.toLowerCase() === "dhn") return c.dhn === true
      return true
    })
    .sort((c1, c2) => c2.ordre - c1.ordre)

  return {
    SUPER_TOGGLE, fetchSeries, series, selectedSeries, confirmSeriesSelection, removeSerie, openCategorie, resetSeries,
    fetchCompetitions, competitions, selectedCompetitions, getSortedSelectedCompetitions, isCompetitionSelected, isCompetitionTmpSelected, selectCompetition, confirmCompetitions, resetCompetitions,
    fetchAgeCategories, ageCategories, selectedAgeCategories, isAgeCategorySelected, isAgeCategoryTmpSelected, selectAgeCategory, confirmAgeCategories, resetAgeCategories,
    setupIndic, indicateurs, selectedIndicateurs, isIndicatorSelected, selectIndicator, resetIndics,
    fetchCollectifs, collectifs, selectedCollectifs, isCollectifSelected, isCollectifTmpSelected, selectCollectif, confirmCollectifSelection, resetCollectifs,
    isFiltersDataFetched,
  }
}

export default useResultatFilter