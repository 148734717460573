<template>
  <div  class="flex gap-2 flex-wrap items-center z-30">
    <SerieFilter />
    <CompetitionFilter />
    <IndicateurFilter />
    <AgeCategoriesFilter />
    <CollectifFilter alignContentBox="right" />
    <ResetFilterButton :on-button-click="resetFilters" button-style="thickBorder"/>
  </div>
</template>

<script setup>
  import { onMounted } from 'vue'

  import CompetitionFilter from "@/components/pages/haute-performance/filtres/resultat-filter/CompetitionFilter.vue";
  import IndicateurFilter from "@/components/pages/haute-performance/filtres/resultat-filter/IndicateurFilter.vue";
  import CollectifFilter from "@/components/pages/haute-performance/filtres/resultat-filter/CollectifFilter.vue";
  import ResetFilterButton from "@/components/common/ResetFilterButton.vue";
  import SerieFilter from "@/components/pages/haute-performance/filtres/resultat-filter/SerieFilter.vue";

  import { resultatFilter } from '@/composables/useFilters'
  import AgeCategoriesFilter
    from "@/components/pages/haute-performance/filtres/resultat-filter/AgeCategoriesFilter.vue";

  const {
    fetchSeries, resetSeries,
    fetchCollectifs, resetCollectifs,
    setupIndic, resetIndics,
    fetchCompetitions, resetCompetitions,
    fetchAgeCategories, resetAgeCategories,
    isFiltersDataFetched
  } = resultatFilter

  onMounted(async () => {
    if (!isFiltersDataFetched()) {
      setupIndic()
      await Promise.all([
        fetchSeries(),
        fetchCollectifs(),
        fetchCompetitions(),
        fetchAgeCategories(),
      ])
    }
  })
  const resetFilters = () => {
    resetSeries()
    resetCollectifs()
    resetIndics()
    resetCompetitions()
    resetAgeCategories()
  }
</script>